const getIsValidPhoneWithoutCode: (phone: string) => boolean = phone => {
  try {
    const regex: RegExp = /^\b((\(\d{1,3}\))|\d{1,3})[-.\s]?(\d{1,4})[-.\s]?(\d{1,4})\b$/;

    if (!regex.test(phone) || phone === '') return false;

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const getIsValidPhoneNumber: (phone: string) => boolean = phone => {
  try {
    const regex: RegExp = /^\+?\d{1,3}[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

    if (!regex.test(phone) || phone === '') return false;

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const toggleMultiOptionPhoneNumberInputOptionsActive: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const target: any = event?.currentTarget || event?.target;
    const multiOptionPhoneNumberInput: any = target?.closest('.multiOptionPhoneNumberInput') || null;

    multiOptionPhoneNumberInput.classList.toggle('active');
  } catch (error) {
    console.error(error);
  }
};

const handleMultiOptionPhoneNumberInputOptionSelect: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const option: any = event?.currentTarget || event?.target;
    const optionPrefix: string = option?.dataset?.prefix || '';
    const optionSource: string = option?.dataset?.source || '';
    const optionAlt: string = option?.dataset?.alt || '';
    const multiOptionPhoneNumberInput: any = option?.closest('.multiOptionPhoneNumberInput') || null;
    const finalPhoneInput: any = multiOptionPhoneNumberInput.getElementsByTagName('INPUT')[0];
    const currentPrefixContainer: any = multiOptionPhoneNumberInput?.getElementsByClassName('currentPrefix')[0];
    const currentFlagContainer: any = multiOptionPhoneNumberInput?.getElementsByClassName('currentFlag')[0];
    const currentFlag: any = currentFlagContainer?.getElementsByTagName('IMG')[0];
    var phoneNumber: string = finalPhoneInput?.value || '';

    phoneNumber = phoneNumber.replace(currentPrefixContainer.innerText, optionPrefix);
    finalPhoneInput.value = phoneNumber;
    currentPrefixContainer.innerText = optionPrefix;
    currentFlag.src = optionSource;
    currentFlag.alt = optionAlt;
    multiOptionPhoneNumberInput.classList.remove('active');
  } catch (error) {
    console.error(error);
  }
};

const validateMultiOptionPhoneNumberInput: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const target: any = event?.currentTarget || event?.target;
    const multiOptionPhoneNumberInput: any = target?.closest('.multiOptionPhoneNumberInput') || null;
    const phoneNumber: string = target?.value || '';
    const isRequired: boolean = multiOptionPhoneNumberInput.classList.contains('required');
    const finalPhoneInput: any = multiOptionPhoneNumberInput.getElementsByTagName('INPUT')[0];
    const prefixContainer: any = multiOptionPhoneNumberInput?.getElementsByClassName('currentPrefix')[0];
    const prefixValue: string = prefixContainer?.innerText || '';

    multiOptionPhoneNumberInput.classList.remove('active');

    if (isRequired) {
      const isValidPhoneNumber: boolean = getIsValidPhoneWithoutCode(phoneNumber);

      if (!isValidPhoneNumber) multiOptionPhoneNumberInput.classList.add('invalid');

      finalPhoneInput.value = prefixValue + phoneNumber;
      return;
    }

    if (phoneNumber !== '') {
      const isValidPhoneNumber: boolean = getIsValidPhoneWithoutCode(phoneNumber);

      if (!isValidPhoneNumber) multiOptionPhoneNumberInput.classList.add('invalid');

      finalPhoneInput.value = prefixValue + phoneNumber;
      return;
    }
  } catch (error) {
    console.error(error);
  }
};

const clearMultiOptionPhoneNumberInputError: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const input: any = event?.currentTarget || event?.target;
    const parent: any = input?.closest('.multiOptionPhoneNumberInput') || null;

    parent.classList.remove('invalid');
  } catch (error) {
    console.error(error);
  }
};

const setMultiOptionPhoneNumberInputError: (
  multiOptionPhoneNumberInputID: string
) => void = multiOptionPhoneNumberInputID => {
  try {
    const multiOptionPhoneNumberInput: any = document.getElementById(multiOptionPhoneNumberInputID);
    const parent: any = multiOptionPhoneNumberInput?.closest('.multiOptionPhoneNumberInput') || null;

    parent.classList.add('invalid');
  } catch (error) {
    console.error(error);
  }
};

const relocateMultiOptionPhoneNumberInputValueByIdToNewInputByIdAndReturnSuccess: (
  MultiOptionPhoneNumberInputID: string,
  newInputID: string
) => boolean = (MultiOptionPhoneNumberInputID, newInputID) => {
  try {
    const MultiOptionPhoneNumberInput: any = document.getElementById(MultiOptionPhoneNumberInputID);
    const newInput: any = document.getElementById(newInputID);
    const phoneNumberValue: string = MultiOptionPhoneNumberInput?.value || '';

    newInput.value = phoneNumberValue;
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
